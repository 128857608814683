<template>
  <div>
    <stepper-pagination
      v-if="firstTime"
      :additional-text="$t('pages.onBoarding.ideaCatalogue.createAnIdea')"
      color="blue"
      class="text-uppercase mb-2"
      :number-of-steps="4"
      :active-step="3"
    />
    <h1 class="mb-2">
      {{ $t('pages.onBoarding.ideaCatalogue.title') }}
    </h1>

    <div class="d-flex justify-content-center">
      <p class="text-lead mr-1">
        {{ $t('pages.onBoarding.ideaCatalogue.subtitle') }}
      </p>
      <ib-tooltip popper-class="plan-popper">
        <div slot="content" class="tooltip-content">
          {{ $t('pages.onBoarding.ideaCatalogue.tooltip') }}
        </div>
        <span class="text-primary-color o-100 text-underline cursor-pointer h5">
          {{ $t('pages.onBoarding.ideaCatalogue.template') }} <i class="ib-icon-info" />
        </span>
      </ib-tooltip>
    </div>
  </div>
</template>

<script>
import MixinUser from '@/mixins/user'
import StepperPagination from '@/components/_v2/StepperPagination'

export default {
  name: 'SelectIdeaHeader',

  components: {
    StepperPagination
  },

  mixins: [MixinUser]
}
</script>
