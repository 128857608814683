<template>
  <div class="template-accordion-wrapper">
    <p class="cursor-pointer" @click="show = !show">
      <i class="icon ib-icon ib-icon-world" />
      {{ $t('seeTemplatesInEnglish') }}
      <span :class="{'el-icon-arrow-down': !show, 'el-icon-arrow-up': show}" />
    </p>

    <div v-show="show">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateAccordion',

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.template-accordion-wrapper {
  p {
    opacity: 0.5;
    font-size: 18px;
  }
}
</style>
