<template>
  <ib-dialog
    class="dialog-idea-type"
    :visible="visible"
    :show-close="false"
    width="961px"
    @open="onOpen"
  >
    <template #title>
      <stepper-pagination
        v-if="firstTime"
        :additional-text="$t('pages.onBoarding.dialogIdeaType.name')"
        color="blue"
        class="text-uppercase mb-2"
        :active-step="2"
        :number-of-steps="4"
      />
      <h1 class="m-0 mb-3">
        {{ $t('pages.onBoarding.dialogIdeaType.title') }}
      </h1>
      <h5 class="m-0 subtitle">
        {{ $t('pages.onBoarding.dialogIdeaType.subtitle') }}
      </h5>
    </template>

    <el-row :gutter="10" class="d-flex flex-wrap mb-4">
      <el-col
        v-for="(ideaType, key) in ideaTypeItems"
        :key="key"
        class="d-flex"
        :sm="12"
        :lg="6"
      >
        <idea-type-card
          class="mb-2 mb-sm-2 mb-md-2 mb-lg-0"
          :title="ideaType.title"
          :subtitle="ideaType.subtitle"
          :img="ideaType.img"
          :type="ideaType.type"
          :active="type === ideaType.type"
          @click="type = $event"
        />
      </el-col>
    </el-row>

    <template #footer>
      <ib-divider class="mb-4" block />
      <div class="d-flex align-items-center justify-content-end">
        <ib-dialog-footer
          button-size="md"
          :submit-btn-text="$t('continue')"
          :cancel-btn-text="$t('pages.onBoarding.dialogIdeaType.backToIdeaCollection')"
          :visible-cancel-btn="!!(!firstTime && hasIdeasOrSharedIdeas)"
          :disable-submit-btn="!type"
          @submit="$emit('close', type)"
          @cancel="$router.push({name: 'idea-collection'})"
        />
      </div>
    </template>
  </ib-dialog>
</template>

<script>
import IdeaTypeCard from '@/views/SelectIdea/Components/IdeaTypeCard'
import IdeaTypesEnum from '@/constants/IdeaTypesEnum'
import MixinUser from '@/mixins/user'
import StepperPagination from '@/components/_v2/StepperPagination'

export default {
  name: 'DialogIdeaType',

  components: {
    StepperPagination,
    IdeaTypeCard
  },

  mixins: [MixinUser],

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      type: null
    }
  },

  computed: {
    ideaTypeItems () {
      return [
        {
          title: this.$t('pages.onBoarding.dialogIdeaType.ideaTypes.startupSmallBusiness.title'),
          subtitle: this.$t('pages.onBoarding.dialogIdeaType.ideaTypes.startupSmallBusiness.subtitle'),
          img: require('@/assets/img/illustrations/select-idea/startup-small-business.png'),
          type: IdeaTypesEnum.STARTUP_SMALL_BUSINESS
        },
        {
          title: this.$t('pages.onBoarding.dialogIdeaType.ideaTypes.productService.title'),
          subtitle: this.$t('pages.onBoarding.dialogIdeaType.ideaTypes.productService.subtitle'),
          img: require('@/assets/img/illustrations/select-idea/product-service.png'),
          type: IdeaTypesEnum.PRODUCT_SERVICE
        },
        {
          title: this.$t('pages.onBoarding.dialogIdeaType.ideaTypes.projectEvent.title'),
          subtitle: this.$t('pages.onBoarding.dialogIdeaType.ideaTypes.projectEvent.subtitle'),
          img: require('@/assets/img/illustrations/select-idea/project-event.png'),
          type: IdeaTypesEnum.PROJECT_EVENT
        },
        {
          title: this.$t('pages.onBoarding.dialogIdeaType.ideaTypes.justWannaTry.title'),
          subtitle: this.$t('pages.onBoarding.dialogIdeaType.ideaTypes.justWannaTry.subtitle'),
          img: require('@/assets/img/illustrations/select-idea/just-wanna-try.png'),
          type: IdeaTypesEnum.JUST_WANNA_TRY
        }
      ]
    }
  },

  methods: {
    onOpen () {
      this.type = null
      this.$router.replace({ query: this.$router.currentRoute.query, hash: '#ideatype' })
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-idea-type {
  .step-text,
  .subtitle {
    color: $color-text-gray;
  }
}
</style>
