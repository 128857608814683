<template>
  <!-- Dialog -->
  <ib-dialog
    width="960px"
    top="8vh"
    :show-close="false"
    :visible="visible"
    empty-header
    no-scroll-to-top-after-close
    @open="onDialogOpen"
    @closed="onDialogClosed"
  >
    <template #title>
      <stepper-pagination
        v-if="firstTime"
        :additional-text="$t('dialogs.ideaInfo.name')"
        color="blue"
        class="text-uppercase mb-2 mb-md-4"
        :active-step="3"
        :number-of-steps="4"
      />
    </template>

    <div class="text-center d-md-none">
      <!-- Title -->
      <h2 class="m-0 mb-2">
        {{ other ? ideaCategoryName : ideaName }}
      </h2>
      <!-- /Title -->
      <!-- Subtitle -->
      <p v-if="!other" class="h5 text-muted m-0 mb-3">
        {{ ideaCategoryName }}
      </p>
      <!-- /Subtitle -->
    </div>

    <!-- Dialog Body -->
    <el-row :gutter="20">
      <!-- Illustration -->
      <el-col :md="10" class="mb-4 mb-md-0">
        <div
          class="illustration-wrapper m-auto"
          :style="{ backgroundImage: `url('${illustration}')`}"
        >
          <div
            class="icon-wrapper"
            :style="{ backgroundImage:`url('${icon}')` }"
          />
        </div>
      </el-col>
      <!-- /Illustration -->

      <!-- Dialog Right Side -->
      <el-col :md="14">
        <!-- Header -->
        <header class="d-none d-md-block">
          <!-- Title -->
          <h2 class="h4 mt-0">
            {{ other ? ideaCategoryName : ideaName }}
          </h2>
          <!-- /Title -->
          <!-- Subtitle -->
          <p v-if="!other" class="text-regular text-muted">
            {{ ideaCategoryName }}
          </p>
          <!-- /Subtitle -->
        </header>
        <!-- /Header -->
        <!-- Idea Description -->
        <p v-if="other" class="idea-desc text-regular">
          You have a brilliant idea that will solve a well-defined market problem and provide a solution to your target customers.<br><br>

          Solid financial planning and a good marketing strategy will be essential, as well as continuous learning skills and patience for dealing with demanding customers.<br><br>

          With a well-crafted business model and a plan in place, you will carve your path to success!
        </p>
        <p v-else class="idea-desc text-regular" v-html="description" />
        <!-- /Idea Description -->
      </el-col>
      <!-- /Dialog Right Side -->
    </el-row>

    <ib-divider class="mt-4" />

    <el-row>
      <el-col>
        <h4 class="mb-4">
          {{ $t('dialogs.ideaInfo.chooseHowYouWantToWork') }}
        </h4>
        <template-chooser
          v-model="template"
          :has-template="hasTemplate"
        />
      </el-col>
    </el-row>
    <!-- /Dialog Body -->

    <!-- Dialog Footer -->

    <!-- Footer Content -->
    <template #footer>
      <ib-divider class="mb-4" block />
      <div class="d-flex align-items-center justify-content-end">
        <!-- Choose Something Else & Select This Idea -->
        <ib-dialog-footer
          button-size="md"
          :submit-btn-text="$t('dialogs.ideaInfo.selectThisIdea')"
          :cancel-btn-text="$t('dialogs.ideaInfo.chooseSomethingElse')"
          @submit="selectIdea"
          @cancel="closeDialog"
        />
        <!-- /Choose Something Else & Select This Idea -->
      </div>
    </template>
    <!-- /Footer Content -->

    <!-- /Dialog Footer -->

  </ib-dialog>
  <!-- /Dialog -->
</template>

<script>
import IdeaTypesEnum, { getTypeNames } from '@/constants/IdeaTypesEnum'
import MixinUser from '@/mixins/user'
import StepperPagination from '@/components/_v2/StepperPagination'
import TemplateChooser from '@/views/SelectIdea/Components/TemplateChooser'
import _find from 'lodash/find'

export default {
  name: 'DialogIdeaInfo',

  components: {
    StepperPagination,
    TemplateChooser
  },

  mixins: [MixinUser],

  props: {
    visible: Boolean,
    idea: {
      default () {
        return {}
      },
      type: Object
    },
    category: {
      default () {
        return {}
      },
      type: Object
    },
    ideaType: {
      type: Number,
      default: IdeaTypesEnum.STARTUP_SMALL_BUSINESS
    },
    other: {
      type: Boolean,
      default: false
    },
    templateOnCurrentLanguage: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      template: false
    }
  },

  computed: {
    ideaCategoryName () {
      if (this.category && this.category.options) {
        return this.templateOnCurrentLanguage ? this.category.options[localStorage.getItem('lang')].name : this.category.options.en.name
      }

      return null
    },

    ideaName () {
      if (this.idea && this.idea.options) {
        return this.templateOnCurrentLanguage ? this.idea.options[localStorage.getItem('lang')].name : this.idea.options.en.name
      }

      return null
    },

    description () {
      if (this.idea && this.idea.options) {
        return this.templateOnCurrentLanguage ? this.idea.options[localStorage.getItem('lang')].description : this.idea.options.en.description
      }

      return null
    },

    illustration () {
      if (!this.isEmpty(this.idea) && this.idea.cover) return `${this.baseUrl}${this.idea.cover.path}${this.idea.cover.filename}`
      if (!this.isEmpty(this.category) && this.category.cover) return `${this.baseUrl}${this.category.cover.path}${this.category.cover.filename}`

      return null
    },

    icon () {
      if (!this.isEmpty(this.idea) && this.idea.icon) return `${this.baseUrl}${this.idea.icon.path}${this.idea.icon.filename}`
      if (!this.isEmpty(this.category) && this.category.icon) return `${this.baseUrl}${this.category.icon.path}${this.category.icon.filename}`

      return null
    },

    hasTemplate () {
      if (this.templateOnCurrentLanguage) {
        return _find(this.idea.templates, ['lang', localStorage.getItem('lang')]) !== undefined
      }

      return _find(this.idea.templates, ['lang', 'en']) !== undefined
    }
  },

  methods: {
    onDialogOpen () {
      if (this.ideaType === IdeaTypesEnum.STARTUP_SMALL_BUSINESS) {
        this.template = !!this.idea.templates.length
      } else {
        this.template = this.hasTemplate
      }
      const typeName = getTypeNames(this.ideaType)
      this.$router.replace({ query: this.$route.query, hash: `#${typeName}-selectedidea` })
    },

    closeDialog () {
      this.$emit('close-dialog')
    },

    onDialogClosed () {
      this.$emit('dialog-closed')
    },

    selectIdea () {
      this.$emit('select-idea', { template: this.template })
    },

    isEmpty (obj) {
      return Object.keys(obj).length === 0
    }
  }
}
</script>
<style scoped lang="scss">
.illustration-wrapper {
  position: relative;
  display: block;
  width: 100%;
  max-width: 350px;
  height: auto;
  min-height: 330px;
  border-radius: 4.5px;
  border: solid 1px #e8e8e8;
  background-image: url("~@/assets/img/illustrations/testing-idea-cards.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  .icon-wrapper {
    position: absolute;
    bottom: 6px;
    left: 6px;
    width: 62px;
    height: 62px;
    border-radius: 5.2px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.14), 0 0 3px 0 rgba(0, 0, 0, 0.12);
    background-image: url("~@/assets/img/icons/coffee-colored-icon.svg");
    background-size: 44px;
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>
