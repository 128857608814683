<template>
  <el-row :gutter="30" class="ib-template-chooser-wrapper d-flex flex-wrap">
    <el-col class="d-flex mb-3 mb-md-0" :md="12">
      <div
        :class="{'selected': !template}"
        class="ib-template-chooser-item d-flex align-items-center flex-grow-1"
        @click="onChange(false)"
      >
        <div class="mr-4">
          <img
            src="@/assets/img/icons/idea-settings/from-scratch.svg"
            width="55"
            alt="logo"
          >
        </div>
        <div>
          <p class="m-0 h5 font-weight-bold mb-1">
            {{ $t('startFromScratch') }}
          </p>
          <p class="m-0 font-weight-bold">
            {{ $t('startFromScratchDescription') }}
          </p>
        </div>
      </div>
    </el-col>
    <el-col class="d-flex" :md="12">
      <div
        :class="{'selected': template, 'disabled': !hasTemplate}"
        class="ib-template-chooser-item d-flex align-items-center flex-grow-1"
        @click="onChange(true)"
      >
        <div class="mr-4">
          <img
            src="@/assets/img/icons/idea-settings/use-template.svg"
            width="55"
            alt="logo"
          >
        </div>
        <div v-if="hasTemplate">
          <p class="m-0 h5 font-weight-bold mb-1">
            {{ $t('useTemplate') }}
          </p>
          <p class="m-0 font-weight-bold">
            {{ $t('useTemplateDescription') }}
          </p>
        </div>
        <div v-else>
          <p class="m-0 h5 font-weight-bold mb-1">
            {{ $t('templateIsNotAvailable') }}
          </p>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'TemplateChooser',

  props: {
    value: {
      type: Boolean,
      required: true
    },
    hasTemplate: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    template: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },

  watch: {
    hasTemplate: {
      handler (newValue) {
        this.template = newValue
      },
      immediate: true
    }
  },

  methods: {
    onChange (template) {
      if (!this.hasTemplate) return
      this.template = template
      this.$emit('input', template)
    }
  }

}
</script>

<style scoped lang="scss">
.ib-template-chooser-wrapper {
  .ib-template-chooser-item {
    min-height: 90px;
    border-radius: 15px;
    padding: 5px 15px;
    opacity: 0.3;
    border: 1px solid grey;
    cursor: pointer;

    &.selected {
      opacity: 1;
      border: 2px solid $color-primary;
      transition: opacity 0.25s;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}
</style>
