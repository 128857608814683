<!--suppress JSUnresolvedVariable -->
<template>
  <div>

    <!-- Loader -->
    <loader v-if="Object.values(fetched).some(item => item === false)" />
    <!-- /Loader -->

    <!-- Select Idea & Dialogs -->
    <div v-else>
      <!-- Select Idea -->
      <div id="select-idea" :class="{'blur': !showBackground || blurPage, 'sidebar-open': sidebarOpen}">
        <div class="container-1200">

          <div class="d-flex justify-content-between align-items-center">
            <!-- Logo -->
            <a class="logo">
              <img src="@/assets/img/logos/ideaBuddy-color.svg">
            </a>
            <!-- /Logo -->
            <!-- Back button -->
            <ib-button
              v-if="showBackground"
              variant="white"
              font-family="regular"
              @click="onBack"
            >
              {{ $t('back') }}
            </ib-button>
            <!-- /Back button -->
          </div>

          <!-- Header -->
          <header v-if="showBackground" class="onboarding-header mt-5 mb-5 mb-xl-5">
            <select-idea-header />
          </header>
          <!-- /Header -->

          <div v-if="showBackground">
            <!-- Start from scratch -->
            <el-row
              :gutter="20"
              class="d-flex flex-wrap mb-4"
            >
              <el-col
                :xs="12"
                :sm="6"
                :md="6"
                :lg="4"
                class="d-flex"
              >
                <add-new-card
                  class="start-from-scratch-card"
                  :title="$t('startFromScratch')"
                  min-height="185px"
                  @click="onStartFromScratch"
                />
              </el-col>
              <template v-if="!isEnglishLanguage">
                <el-col
                  v-for="item in ideasOnCurrentLanguage"
                  :key="item.id"
                  :xs="12"
                  :sm="6"
                  :md="6"
                  :lg="4"
                  class="d-flex"
                >
                  <idea-card
                    :idea="item"
                    translate
                    @select-idea="selectIdea($event, true)"
                  />
                </el-col>
              </template>
            </el-row>
            <!-- /Start from scratch -->

            <component
              :is="ideasOnCurrentLanguage.length && !isEnglishLanguage ? 'template-accordion' : 'div'"
              v-model="templateInEnglishVisible"
            >
              <!-- Popular ideas -->
              <ideas-section
                class="mb-4"
                :title="$t('popularIdeas')"
                :data="popularIdeas"
                @select-idea="selectIdea($event)"
              />
              <!-- /Popular ideas -->

              <!-- Other ideas -->
              <ideas-section
                v-for="idea in ideas"
                :key="idea.id"
                class="mb-4"
                :title="idea.name"
                :data="idea.items"
                @select-idea="selectIdea($event)"
              />
              <!-- /Other ideas -->
            </component>
          </div>
        </div>
      </div>
      <!-- /Select Idea -->

      <!-- Dialogs -->
      <div>
        <!-- Dialog User Details -->
        <dialog-user-details
          :visible="dialogUserDetailsVisible"
          @close-dialog="onCloseDialogUserDetails"
        />
        <!-- /Dialog User Details -->

        <!-- Dialog Idea Info -->
        <dialog-idea-info
          :idea="selectedIdea"
          :category="selectedIdeaCategory"
          :visible="dialogIdeaInfoVisible"
          :idea-type="ideaType"
          :other="other"
          :template-on-current-language="templateOnCurrentLanguage"
          @close-dialog="onCloseDialogIdeaInfo"
          @select-idea="onSelectIdea($event)"
        />
        <!-- /Dialog Idea Info -->

        <!-- Dialog Idea Settings -->
        <dialog-idea-settings
          :idea="selectedIdea"
          :category="selectedIdeaCategory"
          :visible="dialogIdeaSettingsVisible"
          :template="template"
          :start-from-scratch="startFromScratch"
          :idea-type="ideaType"
          :catalogue="catalogue"
          :template-on-current-language="templateOnCurrentLanguage"
          @close-dialog="onCloseDialogIdeaSettings"
          @dialog-closed="resetSelectedIdea"
        />
      </div>

      <dialog-idea-type
        :visible="dialogIdeaTypeVisible"
        @close="onCloseDialogIdeaType"
      />
      <!-- /Dialogs -->

    </div>
    <!-- /Select Idea & Dialogs -->
  </div>
</template>

<script>
import AddNewCard from '@/components/_v2/AddNewCard'
import DialogIdeaInfo from './Dialogs/DialogIdeaInfo'
import DialogIdeaSettings from './Dialogs/DialogIdeaSettings'
import DialogIdeaType from '@/views/SelectIdea/Dialogs/DialogIdeaType'
import DialogUserDetails from './Dialogs/DialogUserDetails'
import IdeaCard from '@/views/SelectIdea/Components/IdeaCard.vue'
import IdeaTypesEnum from '@/constants/IdeaTypesEnum'
import IdeasSection from '@/views/SelectIdea/Components/IdeasSection'
import Loader from '@/components/Loader'
import MixinUser from '@/mixins/user'
import SelectIdeaHeader from '@/views/SelectIdea/Components/SelectIdeaHeader'
import TemplateAccordion from '@/views/SelectIdea/Components/TemplateAccordion.vue'
import _find from 'lodash/find'
import _omit from 'lodash/omit'

import axios from 'axios'

import { mapActions, mapGetters } from 'vuex'
import { scrollToTop } from '@/helpers/scrollHelpers'

const PAGE_SIZE = 20

const CancelToken = axios.CancelToken
let source = null

export default {
  name: 'SelectIdea',

  components: {
    TemplateAccordion,
    AddNewCard,
    DialogIdeaInfo,
    DialogIdeaSettings,
    DialogIdeaType,
    DialogUserDetails,
    IdeaCard,
    IdeasSection,
    Loader,
    SelectIdeaHeader
  },

  mixins: [
    MixinUser
  ],

  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      categories: [],
      catalogue: [],
      defaultActiveIndex: '0',
      ideas: [],
      ideasOnCurrentLanguage: [],
      catalogueIdeaTypes: [],
      popularIdeas: [],
      templateOnCurrentLanguage: false,
      count: 0,
      other: false,
      countFiltered: 0,
      collapsedNewIdeas: false,
      selectedIdea: {},
      selectedIdeaCategory: {},
      template: false,
      startFromScratch: false,
      ideaType: null,
      currentFilteredCategory: {},
      fetched: {
        categories: false,
        ideas: false,
        current: false
      },
      params: {
        page: 0,
        search: [],
        category: 0
      },
      dialogIdeaTypeVisible: false,
      dialogUserDetailsVisible: false,
      dialogUserSuccessVisible: false,
      dialogIdeaSettingsVisible: false,
      dialogIdeaInfoVisible: false,
      sidebarOpen: false,
      isIdeaAlreadySaved: false,
      templateInEnglishVisible: false
    }
  },

  computed: {
    ...mapGetters('selectIdea', [
      'paramsFiltered',
      'loadingFiltering',
      'done'
    ]),

    ...mapGetters('ideaCollection', [
      'getIdeasCount',
      'getSharedIdeasCount'
    ]),

    showBackground () {
      return this.ideaType === IdeaTypesEnum.STARTUP_SMALL_BUSINESS && !this.shouldShowDialogUserDetails()
    },

    blurPage () {
      return this.dialogUserDetailsVisible ||
        this.dialogIdeaSettingsVisible ||
        this.dialogIdeaInfoVisible ||
        this.dialogIdeaTypeVisible
    },

    isTemplateAdmin () {
      return this.$store.getters['user/isTemplateAdmin']
    },

    hasIdeasOrSharedIdeas () {
      return this.getIdeasCount || this.getSharedIdeasCount
    },

    isEnglishLanguage () {
      return localStorage.getItem('lang') === 'en'
    }
  },

  created () {
    if (!this.isTemplateAdmin && (this.$store.getters['user/ideasCount'] >= this.$store.getters['user/ideasInPlan'])) {
      this.$router.push({ name: 'idea-collection' })
    }
    this.$store.commit('selectIdea/setPage', 0)
    this.$store.commit('selectIdea/setSearch', [])
    this.$store.commit('selectIdea/setCategory', 0)
    this.$store.commit('selectIdea/setDone', false)

    const promises = [this.getCurrentUserData(), this.getCategories(), this.getTypesIdeas()]
    if (this.isTemplateFromSite) {
      promises.push(this.onSelectCategory({ id: 'template', name: 'Ideas with Templates' }))
    } else {
      promises.push(this.getIdeas())
    }

    Promise.all(promises).then(() => {
      const allIdeasKeys = Object.keys(this.ideas)
      const ideas = allIdeasKeys.reduce((a, key) => {
        return a.concat(this.ideas[key].items)
      }, [])

      const allTemplates = ideas.concat(this.ideasOnCurrentLanguage).reduce((a, item) => {
        return a.concat(item.templates)
      }, [])

      const foundTemplate = _find(allTemplates, ['id', Number(this.$router.currentRoute.query.template)])
      const mergedIdeas = ideas.concat(this.ideasOnCurrentLanguage)

      const queryParameters = this.isTemplateFromSite && foundTemplate
        ? this.$router.currentRoute.query
        : _omit(this.$router.currentRoute.query, ['template'])

      if (this.isTemplateFromSite) {
        this.defaultActiveIndex = 'ideas-with-templates'
        this.ideaType = IdeaTypesEnum.STARTUP_SMALL_BUSINESS

        if (foundTemplate) {
          const selectedIdea = _find(mergedIdeas, ['id', foundTemplate.catalogueIdeaId])

          this.template = true
          this.selectedIdea = selectedIdea
          this.templateOnCurrentLanguage = localStorage.getItem('lang') === foundTemplate.lang
        }
      }

      if (this.shouldShowDialogUserDetails()) {
        this.dialogUserDetailsVisible = true
        this.$router.replace({ hash: '#welcome', query: { m: 'first-idea', ...queryParameters } })
      } else if (this.$route.fullPath.includes('#startup-catalog')) {
        this.ideaType = IdeaTypesEnum.STARTUP_SMALL_BUSINESS
      } else {
        if (this.isTemplateFromSite) {
          if (foundTemplate) {
            this.dialogIdeaInfoVisible = true
            this.$router.replace({ hash: '#startup-selectedidea', query: queryParameters })
          } else {
            this.$router.replace({ hash: '#startup-catalog', query: queryParameters })
          }
        } else {
          this.dialogIdeaTypeVisible = true
          this.$router.replace({ hash: '#ideatype', query: queryParameters })
        }
      }
    })
  },

  methods: {
    ...mapActions('user', ['getCurrentUser']),

    onBack () {
      this.$router.replace({ query: this.$route.query, hash: '#ideatype' })
      this.dialogIdeaTypeVisible = true
      this.ideaType = null
    },

    onCloseDialogIdeaType (type) {
      this.ideaType = type
      this.dialogIdeaTypeVisible = false
      scrollToTop('#select-idea')

      if (IdeaTypesEnum.STARTUP_SMALL_BUSINESS !== type) {
        const idea = this.catalogueIdeaTypes[type]
        this.selectedIdea = idea
        this.templateOnCurrentLanguage = true
        this.selectedIdeaCategory = {
          id: idea.category,
          name: ''
        }
        setTimeout(function () {
          this.dialogIdeaInfoVisible = true
        }.bind(this), 500)
      } else {
        this.$router.replace({ query: this.$route.query, hash: 'startup-catalog' })
      }
    },

    getCategories () {
      return this.$http.get('catalogue/categories', { params: { type: IdeaTypesEnum.STARTUP_SMALL_BUSINESS } })
        .then(response => {
          this.categories = response.data.payload.categories
          setTimeout(() => {
            this.fetched.categories = true
          }, 500)
        }).catch(() => {
          this.fetched.categories = true
        })
    },

    async getIdeas (filtering = false) {
      if (source) {
        await source.cancel()
      }
      source = CancelToken.source()

      if (this.loadingFiltering || this.done) return
      this.$store.commit('selectIdea/showLoadingFiltering')

      try {
        const response = await this.$http.get('catalogue/ideas', { params: this.paramsFiltered, cancelToken: source.token })
        this.catalogue = response.data.payload.catalogue
        this.ideas = response.data.payload.ideas
        this.ideasOnCurrentLanguage = response.data.payload.ideasOnCurrentLanguage

        if (!filtering || !this.popularIdeas.length) {
          this.count = response.data.payload.count
          this.popularIdeas = response.data.payload.popularIdeas
          setTimeout(() => {
            this.fetched.ideas = true
          }, 500)
        }

        if (Object.prototype.hasOwnProperty.call(this.paramsFiltered, 'page') && this.paramsFiltered.page >= 1) {
          this.ideas = this.ideas.concat(response.data.payload.ideas)
        } else {
          this.ideas = []
          this.ideas = response.data.payload.ideas
        }

        this.countFiltered = response.data.payload.countFiltered

        this.$store.commit('selectIdea/setDone', response.data.payload.ideas.length < PAGE_SIZE)
        this.$store.commit('selectIdea/increasePage')
        this.fetched.ideas = true
      } catch (error) {
        this.fetched.ideas = true
      }
      this.$store.commit('selectIdea/hideLoadingFiltering')
    },

    getTypesIdeas () {
      return this.$http.get('catalogue/types')
        .then(response => {
          this.catalogueIdeaTypes = response.data.payload.types
        })
    },

    getCurrentUserData () {
      return this.getCurrentUser()
        .then(_ => {
          setTimeout(() => {
            this.fetched.current = true
          }, 500)
        }).catch(() => {
          this.$store.dispatch('resetState')
          this.$router.push({ name: 'login' })
        })
    },

    onSearch () {
      if (this.params.search) {
        this.$refs.categoriesMenu.activeIndex = '0'
        this.params.category = 0
        this.$store.commit('selectIdea/setCategory', this.params.category)
        this.$store.commit('selectIdea/reset')
        this.ideas = []
      }

      this.$refs.search.visible = false
      this.$store.commit('selectIdea/setSearch', this.params.search)
      this.getIdeas(true)
    },

    onSelectCategory (category) {
      if (category.id !== this.params.category) {
        this.onCloseDialogUserSuccess()
        this.params.search = ''
        this.ideas = []
        this.$store.commit('selectIdea/setSearch', this.params.search)
        this.params.category = category.id
        this.currentFilteredCategory = category
        this.countFiltered = 0
        this.$store.commit('selectIdea/setCategory', this.params.category)
        this.$store.commit('selectIdea/reset')
        this.sidebarOpen = false
        this.selectedIdeaCategory = category
        scrollToTop('.onboarding-header')

        return this.getIdeas(true)
      }
    },

    onCloseDialogUserDetails () {
      this.dialogUserDetailsVisible = false
      const $this = this

      if (this.isTemplateFromSite) {
        setTimeout(() => {
          $this.dialogIdeaInfoVisible = true
          $this.ideaType = IdeaTypesEnum.STARTUP_SMALL_BUSINESS
        }, 500)
      } else {
        setTimeout(() => {
          $this.dialogIdeaTypeVisible = true
          $this.ideaType = null
        }, 500)
      }
    },

    onCloseDialogUserSuccess () {
      this.dialogUserSuccessVisible = false
    },

    openSidebar () {
      this.sidebarOpen = true
    },

    selectIdea (idea, currentLang = false) {
      this.onCloseDialogUserSuccess()
      this.selectedIdea = idea
      this.selectedIdeaCategory = this.getIdeaCategory()
      this.templateOnCurrentLanguage = currentLang
      this.dialogIdeaInfoVisible = true
    },

    getIdeaCategory () {
      for (let i = 0; i < this.categories.length; i++) {
        if (parseInt(this.categories[i].id) === this.selectedIdea.category) {
          return this.categories[i]
        }

        if (typeof this.categories[i].subcategories !== 'undefined') {
          for (const subcategoryId in this.categories[i].subcategories) {
            if (Object.prototype.hasOwnProperty.call(this.categories[i].subcategories, subcategoryId)) {
              if (parseInt(subcategoryId) === this.selectedIdea.category) {
                return this.categories[i].subcategories[subcategoryId]
              }
            }
          }
        }
      }
    },

    onStartFromScratch () {
      this.startFromScratch = true
      this.template = false

      this.selectedIdea = this.catalogueIdeaTypes[IdeaTypesEnum.JUST_WANNA_TRY]
      this.selectedIdeaCategory = {
        id: this.catalogueIdeaTypes[IdeaTypesEnum.JUST_WANNA_TRY].category,
        name: ''
      }
      this.dialogIdeaSettingsVisible = true
    },

    onCloseDialogIdeaInfo () {
      this.dialogIdeaInfoVisible = false
      this.other = false

      if (this.ideaType !== IdeaTypesEnum.STARTUP_SMALL_BUSINESS) {
        setTimeout(function () {
          this.resetSelectedIdea()
          this.dialogIdeaTypeVisible = true
          this.ideaType = null
        }.bind(this), 500)
      } else {
        if (this.isTemplateFromSite) {
          this.$router.replace({ query: _omit(this.$route.query, ['template']), hash: 'startup-catalog' })

          return
        }

        setTimeout(function () {
          this.templateOnCurrentLanguage = false
        }.bind(this), 500)
        this.$router.replace({ query: this.$route.query, hash: 'startup-catalog' })
      }
    },

    onCloseDialogIdeaSettings (ideaSaved) {
      this.dialogIdeaSettingsVisible = false
      this.templateOnCurrentLanguage = false

      if (this.isIdeaAlreadySaved) {
        return
      }

      if (ideaSaved) {
        this.isIdeaAlreadySaved = true
        this.$router.push({ name: 'product-tour' })

        return
      }

      if (this.ideaType !== IdeaTypesEnum.STARTUP_SMALL_BUSINESS) {
        this.ideaType = null
        setTimeout(function () {
          this.dialogIdeaTypeVisible = true
          this.ideaType = null
        }.bind(this), 500)
      } else {
        const queryParameters = this.isTemplateFromSite ? _omit(this.$router.currentRoute.query, ['template']) : this.$router.currentRoute.query
        this.$router.replace({ query: queryParameters, hash: 'startup-catalog' })
      }
    },

    resetSelectedIdea () {
      this.template = false
      this.startFromScratch = false
      this.selectedIdea = {}
      this.selectedIdeaCategory = {}
    },

    onSelectIdea (event) {
      this.template = event.template
      this.dialogIdeaInfoVisible = false
      const $this = this
      setTimeout(() => {
        $this.dialogIdeaSettingsVisible = true
      }, 500)
    },

    shouldShowDialogUserDetails () {
      return this.$store.state.user.firstTime || !this.$store.state.user.firstName || !this.$store.state.user.lastName
    }
  }
}
</script>

<style scoped lang="scss">
  #select-idea {
    background-color: #f5f6fa;
    min-height: 100vh;
  }

  @media only screen and (max-width: 1026px) {
    .flex.buttons {
      display: block;
    }
  }

  .start-from-scratch-card {
    margin-bottom: 20px;
  }
</style>
