<template>
  <div>
    <ideas-section-heading
      class="mb-3"
      :text="title"
    />
    <el-row
      :gutter="20"
      class="d-flex flex-wrap mb-0"
    >
      <el-col
        v-for="item in data"
        :key="item.id"
        class="d-flex"
        :xs="12"
        :sm="6"
        :md="6"
        :lg="4"
      >
        <idea-card
          :idea="item"
          @select-idea="$emit('select-idea', $event)"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import IdeaCard from '@/views/SelectIdea/Components/IdeaCard'
import IdeasSectionHeading from '@/views/SelectIdea/Components/IdeasSectionHeading'

export default {
  name: 'IdeasSection',

  components: {
    IdeasSectionHeading,
    IdeaCard
  },

  props: {
    data: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>
