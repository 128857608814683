<template>
  <div
    :class="idea.cover ? '' : 'bakery'"
    class="idea-card"
    @click="$emit('select-idea', idea)"
  >
    <ib-tooltip
      popper-class="plan-popper"
      content="This idea has a template that you can use as a starting point"
    >
      <div
        class="label-template position-absolute rounded-left text-uppercase"
      >
        {{ $t('template.template') }}
      </div>
    </ib-tooltip>

    <div
      class="card-top"
      :style="{ backgroundImage: cover }"
    >
      <div
        class="card-icon"
        :style="{ backgroundImage: icon }"
      />
    </div>
    <div class="card-bottom">
      <p class="card-title">
        {{ name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IdeaCard',

  props: {
    idea: {
      type: Object,
      required: true
    },
    translate: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },

  computed: {
    cover () {
      return this.idea.cover ? `url('${this.baseUrl}${this.idea.cover.path}${this.idea.cover.filename}')` : null
    },

    icon () {
      return this.idea.icon ? `url('${this.baseUrl}${this.idea.icon.path}${this.idea.icon.filename}')` : null
    },

    name () {
      return this.translate ? this.idea.options[localStorage.getItem('lang')].name : this.idea.name
    }
  }
}
</script>

<style scoped lang="scss">
.idea-card {
  border-right-width: 0;

  .label-template {
    font-size: 12px;
    right: 0;
    top: 0.6rem;
    background-color: #0091ff;
    color: #fff;
    padding: 2px 10px;
    z-index: 1;
  }

  .card-top {
    background-position: center;
  }
}

</style>
