<template>
  <div
    class="idea-type-card d-flex flex-column text-center p-3"
    :class="classes"
    @click="$emit('click', type)"
  >
    <div class="image" :style="{backgroundImage: `url(${img})`}" />

    <p class="m-0 mb-2 title">
      {{ title }}
    </p>
    <p class="m-0 subtitle">
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'IdeaTypeCard',

  props: {
    img: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    type: {
      type: Number,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return {
        selected: this.active
      }
    }
  }
}
</script>

<style scoped lang="scss">
.idea-type-card {
  border: 2px solid #c0c4cc;
  border-radius: 8px;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.25s;
  width: 100%;

  .title {
    font-size: 16px;
    font-family: $outfit-medium;
  }

  .subtitle {
    color: $color-text-gray;
    font-size: 14px;
  }

  .image {
    width: 100%;
    min-height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;

    @media (max-width: $sm) {
      min-height: 140px;
    }
  }

  &.selected {
    border: 2px solid $color-primary;
    opacity: 1;
    transition: all 0.25s;
  }

  &:hover {
    opacity: 1;
    transition: all 0.25s;
  }
}
</style>
