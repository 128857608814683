<template>
  <!-- Dialog -->
  <ib-dialog
    width="960px"
    top="8vh"
    :visible="visible"
    :closable="false"
    empty-header
  >
    <template #title>
      <stepper-pagination
        :additional-text="$t('pages.onBoarding.dialogUserDetails.name')"
        color="blue"
        class="text-uppercase mb-2 mb-md-4"
        :active-step="1"
        :number-of-steps="4"
      />
    </template>

    <!-- Dialog Content -->
    <el-row :gutter="20" class="d-flex align-items-center">

      <!-- Illustration -->
      <el-col :md="12" class="hidden-sm-and-down text-center">
        <img class="illustration" src="@/assets/img/illustrations/doors.svg" alt="">
      </el-col>
      <!-- /Illustration -->

      <!-- Dialog Right Side -->
      <el-col :md="12">
        <div class="content-wrapper">

          <!-- Title -->
          <h1 class="mt-0">
            {{ $t('pages.onBoarding.dialogUserDetails.title') }}
          </h1>
          <!-- /Title -->

          <!-- Subtitle -->
          <p class="text-lead">
            {{ $t('pages.onBoarding.dialogUserDetails.subtitle') }}
          </p>
          <!-- /Subtitle -->

          <!-- Upload Avatar -->
          <ib-tooltip
            placement="top"
            popper-class="plan-popper"
            on-dialog
          >
            <!-- Tooltip Content -->
            <div slot="content" class="tooltip-content">
              <p>{{ $t('pages.onBoarding.dialogUserDetails.addYourPicture') }}</p>
            </div>
            <!-- /Tooltip Content -->
            <el-upload
              class="avatar-uploader mb-3"
              action="/api/v1/upload"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-success="handleAvatarSuccess"
            >
              <!-- Avatar -->

              <div v-if="user.avatar">
                <img :src="user.avatarPreview" class="avatar">
                <i class="el-icon-close" @click.stop="removeImageAvatar" />
              </div>
              <!-- /Avatar -->

              <div v-else>

                <!-- Upload Icon -->
                <i class="el-icon-plus avatar-uploader-icon" />
              <!-- /Upload Icon -->

              </div>
            </el-upload>
          </ib-tooltip>
          <!-- /Upload Avatar -->

          <!-- First & Last Name -->
          <el-row :gutter="10" class="line-input-group mt-3 mb-5">
            <el-col :offset="3" :span="9">
              <el-input v-model="user.firstName" class="line-input text-center" :placeholder="$t('firstName')" />
            </el-col>
            <el-col :span="9">
              <el-input v-model="user.lastName" class="line-input text-center" :placeholder="$t('lastName')" />
            </el-col>
          </el-row>
          <!-- /First & Last Name -->
        </div>
      </el-col>
      <!-- /Dialog Right Side -->

    </el-row>
    <!-- /Dialog Content -->

    <!-- Dialog Footer -->
    <template #footer>
      <ib-divider class="mb-4" block />
      <div class="d-flex justify-content-end">
        <ib-dialog-footer
          button-size="md"
          :loading="loadingContinue"
          :submit-btn-text="$t('continue')"
          :disable-submit-btn="continueDisabled"
          :visible-cancel-btn="false"
          @submit="onContinue"
          @cancel="$emit('close')"
        />
      </div>
    </template>

  </ib-dialog>
  <!-- /Dialog -->
</template>

<script>
import MixinCountries from '@/mixins/countries'
import MixinPartnero from '@/mixins/partnero'
import MixinUser from '@/mixins/user'
import StepperPagination from '@/components/_v2/StepperPagination'
import { mapActions } from 'vuex'

export default {
  name: 'DialogUserDetails',

  components: {
    StepperPagination
  },

  mixins: [
    MixinCountries,
    MixinUser,
    MixinPartnero
  ],

  props: {
    visible: Boolean
  },

  data () {
    return {
      user: {
        avatar: '',
        avatarPreview: '',
        firstName: '',
        lastName: ''
      },
      focus: {
        city: false
      },
      loadingContinue: false
    }
  },

  computed: {
    continueDisabled () {
      const userCopy = Object.assign({}, this.user)
      delete userCopy.avatar
      delete userCopy.avatarPreview

      return Object.values(userCopy).some(x => (x === null || x === ''))
    }
  },

  watch: {
    'user.firstName' () {
      this.user.firstName = this.user.firstName.replace(/^\s+/g, '')
    },

    'user.lastName' () {
      this.user.lastName = this.user.lastName.replace(/^\s+/g, '')
    }
  },

  mounted () {
    this.setUserPredefinedValues()
  },

  methods: {
    ...mapActions('user', ['updateUser']),

    setUserPredefinedValues () {
      this.user.firstName = this.$store.state.user.firstName || ''
      this.user.lastName = this.$store.state.user.lastName || ''
    },

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]

      if (this.user[input]) {
        this.focus[input] = true
      }
    },

    beforeAvatarUpload (file) {
      const isAcceptedFormat = file.type === 'image/jpeg' || file.type === 'image/png'
      const isImageSizeLowerThen5M = file.size / 1024 / 1024 <= 5

      if (!isAcceptedFormat) {
        this.$message.error(`${this.$t('validationMessages.pictureMustBeJpgOrPngFormat')}`)
      }
      if (!isImageSizeLowerThen5M) {
        this.$message.error(`${this.$t('validationMessages.pictureSizeCanNotExceed', { size: '5MB' })}`)
      }

      return isAcceptedFormat && isImageSizeLowerThen5M
    },

    handleAvatarSuccess (result, file) {
      this.user.avatar = result.payload.filename
      this.user.avatarPreview = URL.createObjectURL(file.raw)
    },

    removeImageAvatar () {
      this.user.avatar = ''
      this.user.avatarPreview = ''
    },

    sendUserGtm () {
      this.$gtm.trackEvent({
        category: 'Button',
        action: 'Click',
        label: 'Onboarding - Profile Filled',
        value: 0
      })
    },

    intercomUpdateUser (user) {
      this.$intercom.update({
        name: user.firstName + ' ' + user.lastName
      })
    },

    onContinue () {
      this.loadingContinue = true
      this.updateUser(this.user)
        .then((response) => {
          this.sendUserGtm()
          this.intercomUpdateUser(response.user)
          this.createPartneroCustomer(response.user)
          this.$emit('close-dialog')
          this.loadingContinue = false
        }).catch(() => {
          this.loadingContinue = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.add-photo-tutorial-img {
  left: 100%;
  bottom: 7px;
}

.optional-text-info {
  font-size: 16px;
  color: silver;
}

.content-wrapper {
  text-align: center;
  word-break: break-word;
}

.el-form-item {
  margin-bottom: 10px;
}

.no-margin {
  .el-form-item {
    margin-bottom: 10px;

    @include media-breakpoint-up($sm) {
      margin-bottom: 0;
    }
  }
}

.illustration {
  width: 75%;
}
</style>
