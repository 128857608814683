<template>
  <p class="m-0 o-50 text-regular">
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'IdeasSectionHeading',

  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>
